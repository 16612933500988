<template>
  <div id="misc">
    <img class="misc-mask" height="226" :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)">

    <div class="page-title text-center px-4">
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2">{{ $t('canceled.title') }}</span>
      </h2>
      <p v-for="content in $t('canceled.content')" :key="content" class="text-sm">
        {{ content }}
      </p>
    </div>
    <div class="text-center px-4">
      <v-btn color="primary" href="https://burgerbreton.com" class="mb-4">
        {{ $t('canceled.button.order') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Canceled',
    data () {
      return {};
    },
    mounted: async function () {},
    methods: {}
  };
</script>

<style lang="scss">
  @import '@core/preset/preset/misc.scss';
</style>
